import React from 'react';
import { FaCog } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';

const SiteEmDesenvolvimento = () => {
  const configIconAnimation = useSpring({
    loop: true,
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  });

  return (
    <div style={styles.container}>
      <animated.div style={configIconAnimation}>
        <FaCog style={styles.icon} />
      </animated.div>
      <p style={styles.label}>Site em Desenvolvimento...</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  icon: {
    fontSize: '50px',
    color: 'red',
  },
  label: {
    marginTop: '20px',
    fontSize: '18px',
    color: 'black',
  },
};

export default SiteEmDesenvolvimento;
